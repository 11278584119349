module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"posts":"/opt/build/repo/src/layouts/PostLayout/PostLayout.js","work":"/opt/build/repo/src/layouts/WorkLayout/WorkLayout.js","default":"/opt/build/repo/src/layouts/Default/Default.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1440,"backgroundColor":"rebeccapurple"}},{"resolve":"gatsby-remark-smartypants","options":{"dashes":"oldschool"}},{"resolve":"gatsby-remark-responsive-iframe"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ongki Herlambang","short_name":"herlambang.design","start_url":"/","background_color":"#202020","theme_color":"#202020","display":"minimal-ui","icon":"src/assets/images/o.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-70266275-1","head":true,"anonymize":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
