import styles from './Timer.module.scss'
import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import Text from './../../../components/Text/Text'
import Button from '../../../components/Button/Button'
import Container from '../../../layouts/Container/Container'
import Box from '../../../layouts/Box/Box'

export const TimerFormatted = ({ children, className, ...restProps }) => {
  let [timer, setTimer] = useState()
  let [duration, setDuration] = useState(0)
  let [running, setRunning] = useState(false)

  let [hour, setHour] = useState(0)
  let [minute, setMinute] = useState(0)
  let [second, setSecond] = useState(0)

  useEffect(() => {
    setHour(Math.floor(duration / 3600))
    setMinute(Math.floor((duration % 3600) / 60))
    setSecond((duration % 3600) % 60)
  }, [duration])

  const handler = start => {
    setDuration(Math.floor((Date.now() - start) / 1000))
  }

  useEffect(() => {
    if (running) {
      let startTime = Date.now()
      let t = setInterval(handler, 100, startTime)
      setTimer(t)
    } else {
      clearInterval(timer)
    }
    return () => {
      clearInterval(timer)
    }
  }, [running])

  return (
    <Container post {...restProps}>
      <Box justifyBetween alignCenter className={styles.root}>
        <header>
          <Text component="p">
            <span
              style={{
                display: 'inline-block',
                width: 12,
                height: 12,
                borderRadius: 9999,
                marginRight: 2,
                backgroundColor: running ? '#008384' : '#FF5a5b',
              }}
            />{' '}
            Timer
          </Text>
          <Text heading3 component="p">
            {hour}h : {minute}m : {second}s
          </Text>
        </header>
        <Button
          primary={!running}
          secondary={running}
          small
          onClick={() => setRunning(!running)}
        >
          {running ? 'Stop' : 'Start'}
        </Button>
      </Box>
    </Container>
  )
}

const Timer = ({ children, className, ...restProps }) => {
  let [timer, setTimer] = useState()
  let [duration, setDuration] = useState(0)
  let [running, setRunning] = useState(false)

  const handler = start => {
    setDuration(Math.floor((Date.now() - start) / 100))
  }

  useEffect(() => {
    if (running) {
      let startTime = Date.now()
      let t = setInterval(handler, 100, startTime)
      setTimer(t)
    } else {
      clearInterval(timer)
    }
    return () => {
      clearInterval(timer)
    }
  }, [running])

  return (
    <Container post {...restProps}>
      <Box justifyBetween alignCenter className={styles.root}>
        <header>
          <Text component="p">
            <span
              style={{
                display: 'inline-block',
                width: 12,
                height: 12,
                borderRadius: 9999,
                marginRight: 2,
                backgroundColor: running ? '#008384' : '#FF5a5b',
              }}
            />{' '}
            Timer
          </Text>
          <Text heading3 component="p">
            {Math.floor(duration / 10)} seconds
          </Text>
        </header>
        <Button
          primary={!running}
          secondary={running}
          small
          onClick={() => setRunning(!running)}
        >
          {running ? 'Stop' : 'Start'}
        </Button>
      </Box>
    </Container>
  )
}

export default Timer
