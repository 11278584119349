import styles from './Matrix.module.scss'
import React from 'react'
import cx from 'classnames'

import Text from './../../../components/Text/Text'
import Button from '../../../components/Button/Button'
import Container from '../../../layouts/Container/Container'
import Box from '../../../layouts/Box/Box'

import {
  endOfWeek,
  addDays,
  startOfWeek,
  differenceInCalendarWeeks,
  endOfMonth,
  startOfMonth,
  format,
} from 'date-fns'

/**
 * Returns a two-dimensional array with calendar represented dates
 */
export function generateCalendarMatrix(
  year,
  month,
  formatDay = day => day,
  weekStartsOn = 1
) {
  let date = new Date(year, month)
  let startDay = startOfMonth(date)
  let lastDay = endOfMonth(date)

  const startDate = startOfWeek(startDay, { weekStartsOn })

  const rows =
    differenceInCalendarWeeks(lastDay, startDay, { weekStartsOn }) + 1
  const cols = 7
  const totalDays = rows * cols

  let matrix = Array.from({ length: totalDays })
    .map((_, index) => addDays(startDate, index))
    // .map(day => (typeof formatDay === "function" ? formatDay(day) : day))
    .reduce((matrix, current, index, days) => {
      // index % cols === 0 && console.log("matrix: ", days.slice(index, index + cols))
      return index % cols === 0
        ? [...matrix, days.slice(index, index + cols)]
        : matrix
    }, [])

  return matrix
}

const Matrix = ({ children, className, ...restProps }) => {
  let hello = generateCalendarMatrix(2019, 8)

  return (
    <Container post style={{ marginBottom: 24 }}>
      <div className={styles.root}>
        <Text heading3 as="h1" style={{ marginBottom: 24 }}>
          September 2019
        </Text>

        {hello.map((week, index) => (
          <Box as="ul" key={`${index}--week`}>
            {week.map((day, i) => (
              <Text
                style={{ flex: 1 }}
                heading5
                as="li"
                key={`${i}--day`}
                align="center"
              >
                {format(day, 'd')}
              </Text>
            ))}
          </Box>
        ))}
      </div>
    </Container>
  )
}

export default Matrix
